import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SarovarLogo from '../assets/images/sarovar-logo-final.png'; // Replace with the correct path to your logo
import './login.css';

// Import environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;

function LoginPage({ setToken, setSocket }) {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [error, setError] = useState(null);
  const [loadingOtp, setLoadingOtp] = useState(false); // Loading state for OTP sending
  const [selectedModel, setSelectedModel] = useState(null); // Track model selection
  const [loadingLogin, setLoadingLogin] = useState(false); // Loading state for login
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail === '' || validateEmail(newEmail)) {
      setEmailError('');
    } else {
      setEmailError('Invalid email address');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (isOtpSent) {
        handleOtpVerification();
      } else {
        handleGetToken();
      }
    }
  };

  const handleGetToken = async () => {
    if (validateEmail(email)) {
      setLoadingOtp(true); // Start loading for OTP
      try {
        const response = await fetch(`${API_BASE_URL}/send-otp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setIsOtpSent(true);
          setError(null);
        } else {
          setEmailError('Failed to send OTP. Please check the email address and try again.');
        }
      } catch (err) {
        console.error('Error sending OTP:', err);
        setEmailError('Failed to send OTP. Please try again.');
      } finally {
        setLoadingOtp(false); // Stop loading for OTP
      }
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setOtpError('');
  };

  const handleOtpVerification = async () => {
    setLoadingLogin(true); // Start loading for login
    try {
      const response = await fetch(`${API_BASE_URL}/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.access_token) {
          const accessToken = data.access_token;
          setToken(accessToken);

          // Confirm model selection before navigating
          if (!selectedModel) {
            alert('Please select a model to continue.');
            setLoadingLogin(false);
            return;
          }

          // Directly navigate to LandingPage
          navigate('/landing', { state: { token: accessToken, model: selectedModel } });
        } else {
          setOtpError('No access token found in the response.');
          setLoadingLogin(false); // Stop loading if no access token
        }
      } else {
        setOtpError('Invalid OTP. Please try again.');
        setLoadingLogin(false); // Stop loading if response is not OK
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setOtpError('Failed to verify OTP. Please try again.');
      setLoadingLogin(false); // Stop loading on fetch error
    }
  };



  return (
    <div className="login-page">
      <div className="login-box">
        <img src={SarovarLogo} alt="Sarovar Logo" className="logo" />
        {isOtpSent ? (
          <>
            <p className="otp-message">Please enter the one-time password sent to your email</p>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOtpChange}
              onKeyDown={handleKeyDown}
              className="input-field"
              required
            />
            {otpError && <p className="error-message">{otpError}</p>}

            {/* Model Selection JSX */}
            <div className="model-selection">
              <label>
                <input
                  type="radio"
                  value="normal"
                  checked={selectedModel === 'normal'}
                  onChange={() => setSelectedModel('normal')}
                />
                Normal Model
              </label>
              <label>
                <input
                  type="radio"
                  value="chain_based"
                  checked={selectedModel === 'chain_based'}
                  onChange={() => setSelectedModel('chain_based')}
                />
                Chain-Based Model
              </label>
            </div>

            <button onClick={handleOtpVerification} className="login-button">
              {loadingLogin ? "Verifying OTP..." : "Login"}
            </button>
          </>
        ) : (
          <>
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              className="input-field"
              required
            />
            {emailError && <p className="error-message">{emailError}</p>}
            <button onClick={handleGetToken} className="login-button" disabled={emailError !== '' || email === ''}>
              {loadingOtp ? "Sending OTP..." : "Get Token"}
            </button>
          </>
        )}
        {error && <p className="login-error">{error}</p>}
      </div>
    </div>
  );

}

export default LoginPage;
